import { render, staticRenderFns } from "./trainAdminCreationCompetitionTwo.vue?vue&type=template&id=59481220&scoped=true&"
import script from "./trainAdminCreationCompetitionTwo.vue?vue&type=script&lang=js&"
export * from "./trainAdminCreationCompetitionTwo.vue?vue&type=script&lang=js&"
import style0 from "./trainAdminCreationCompetitionTwo.vue?vue&type=style&index=0&id=59481220&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59481220",
  null
  
)

export default component.exports